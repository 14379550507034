/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./FancyDateDisplay.vue?vue&type=template&id=19dd2e2c&scoped=true"
import script from "./FancyDateDisplay.ts?vue&type=script&lang=ts&external"
export * from "./FancyDateDisplay.ts?vue&type=script&lang=ts&external"
import style0 from "./FancyDateDisplay.vue?vue&type=style&index=0&id=19dd2e2c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19dd2e2c",
  null
  
)

export default component.exports