import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { InventoryClass, Side } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, InventoryClassDisplay, SideDisplay } from '~/nasa_ui/types';
import { getPartReference, putPartReference } from '~/nasa_ui/utils';
let ModalPartReferenceEdit = class ModalPartReferenceEdit extends Mixins(BaseModal) {
    selectedEntity = null;
    InventoryClassDisplay = InventoryClassDisplay;
    InventoryClass = InventoryClass;
    SideDisplay = SideDisplay;
    Side = Side;
    formDataPartReference = {
        asBuiltNumber: null,
        attributes: {},
        birthDate: null,
        calibrationNumber: null,
        contractEndingItemNumber: null,
        drawingDescription: null,
        drawingNumber: null,
        isTool: null,
        itemClass: null,
        lastCalibrationDate: null,
        location: null,
        lotNumber: null,
        notes: null,
        serialNumber: null,
        shelfLifeExpirationDate: null,
        side: null,
        size: null,
        usageLifeExpirationDate: null
    };
    id;
    async onClickOfSavePartReference() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const body = {
                asBuiltNumber: this.formDataPartReference.asBuiltNumber,
                attributes: this.formDataPartReference.attributes,
                birthDate: this.formDataPartReference.birthDate,
                calibrationNumber: this.formDataPartReference.calibrationNumber,
                contractEndingItemNumber: this.formDataPartReference.contractEndingItemNumber,
                drawingDescription: this.formDataPartReference.drawingDescription,
                drawingNumber: this.formDataPartReference.drawingNumber,
                isTool: this.formDataPartReference.isTool ?? false,
                itemClass: this.formDataPartReference.itemClass,
                lastCalibrationDate: this.formDataPartReference.lastCalibrationDate,
                location: this.formDataPartReference.location,
                lotNumber: this.formDataPartReference.lotNumber,
                notes: this.formDataPartReference.notes,
                serialNumber: this.formDataPartReference.serialNumber,
                shelfLifeExpirationDate: this.formDataPartReference.shelfLifeExpirationDate,
                side: this.formDataPartReference.side ?? Side.NONE,
                size: this.formDataPartReference.size,
                usageLifeExpirationDate: this.formDataPartReference.usageLifeExpirationDate
            };
            const resp = await putPartReference(this.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchSelectedEntity() {
        try {
            const resp = await getPartReference(this.id);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onSelectedEntityChanged() {
        if (!this.selectedEntity) {
            return;
        }
        this.formDataPartReference = {
            asBuiltNumber: this.selectedEntity.asBuiltNumber,
            attributes: this.selectedEntity.attributes,
            birthDate: this.selectedEntity.birthDate,
            calibrationNumber: this.selectedEntity.calibrationNumber,
            contractEndingItemNumber: this.selectedEntity.contractEndingItemNumber,
            drawingDescription: this.selectedEntity.drawingDescription,
            drawingNumber: this.selectedEntity.drawingNumber,
            isTool: this.selectedEntity.isTool,
            itemClass: this.selectedEntity.itemClass,
            lastCalibrationDate: this.selectedEntity.lastCalibrationDate,
            location: this.selectedEntity.location,
            lotNumber: this.selectedEntity.lotNumber,
            notes: this.selectedEntity.notes,
            serialNumber: this.selectedEntity.serialNumber,
            shelfLifeExpirationDate: this.selectedEntity.shelfLifeExpirationDate,
            side: this.selectedEntity.side,
            size: this.selectedEntity.size,
            usageLifeExpirationDate: this.selectedEntity.usageLifeExpirationDate
        };
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalPartReferenceEdit.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalPartReferenceEdit.prototype, "fetchSelectedEntity", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalPartReferenceEdit.prototype, "onSelectedEntityChanged", null);
ModalPartReferenceEdit = __decorate([
    Component
], ModalPartReferenceEdit);
export default ModalPartReferenceEdit;
