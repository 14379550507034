var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
import { EntityType } from '~/nasa_ui/types/enums';
import { PartReferenceTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let WorkOrderStepHardware = class WorkOrderStepHardware extends Mixins(ReadOnlyMixin) {
    EntityType = EntityType;
    parts = [];
    tableHeaders = PartReferenceTableHeaders;
    hardwareListId;
    get hardwareTableItems() {
        if (!this.parts.length) {
            return [];
        }
        return transformPartReferenceResponses(this.parts);
    }
    async fetchHardwareList(id) {
        try {
            const resp = await this.$http.get(`/hardware-lists/${id}`);
            if (resp.data.hardwareListParts?.length) {
                this.parts = resp.data.hardwareListParts.map((hwlPart) => hwlPart.partReference);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onChangeHardwareListId(id) {
        if (id) {
            this.fetchHardwareList(id);
        }
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", String)
], WorkOrderStepHardware.prototype, "hardwareListId", void 0);
__decorate([
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], WorkOrderStepHardware.prototype, "onChangeHardwareListId", null);
WorkOrderStepHardware = __decorate([
    Component
], WorkOrderStepHardware);
export default WorkOrderStepHardware;
