import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { getAssemblyTemplate } from '~/nasa_ui/utils';
import { transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalMegaClone = class ModalMegaClone extends Mixins(BaseModal) {
    navigateAfterClone = false;
    selectedEntity = null;
    formData = {
        cloneWaivers: false,
        newRootName: null,
        newRootSubType: null
    };
    get transformedSelectedEntity() {
        if (!this.selectedEntity) {
            return null;
        }
        return transformAssemblyTemplateResponseWithChildrens([this.selectedEntity])[0];
    }
    assemblyTemplateId;
    async onClickOfClone() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const body = {
                cloneWaivers: this.formData.cloneWaivers ?? false,
                newRootName: this.formData.newRootName || this.selectedEntity.name,
                newRootSubType: this.selectedEntity.subType
            };
            const resp = await this.$http.post(`/assembly-templates/${this.selectedEntity.id}/clone`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Cloned.'
            });
            if (this.navigateAfterClone) {
                // navigate to newly cloned assembly template
                this.$router.push({
                    params: {
                        id: resp.data.id
                    }
                });
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchSelectedEntity() {
        if (!this.assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(this.assemblyTemplateId);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalMegaClone.prototype, "assemblyTemplateId", void 0);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalMegaClone.prototype, "fetchSelectedEntity", null);
ModalMegaClone = __decorate([
    Component
], ModalMegaClone);
export default ModalMegaClone;
