var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-card', _vm._b({
    attrs: {
      "border-color": "var(--v-item_instances-base)",
      "start-collapsed": ""
    }
  }, 'c-card', {
    ..._vm.$attrs
  }, false), [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.inventories,
      "icon-color": "var(--v-item_instances-base)"
    }
  }), _c('span', {
    staticClass: "expansion_override_title"
  }, [_vm._v("Associated Hardware ("), _c('small', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.hardwareTableItems.length))]), _vm._v(")")])], 1), _c('div', [_c('AppTable', {
    attrs: {
      "items": _vm.hardwareTableItems,
      "headers": _vm.tableHeaders
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };