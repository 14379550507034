var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "fancy_date_display monospace_font",
    class: {
      'font-weight-bold': _vm.bold
    },
    style: {
      fontSize: _vm.fontSize,
      color: _vm.computedDateAColor
    }
  }, [_vm.computedDateAState === _vm.DateState.RED ? _c('div', {
    staticClass: "icon"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": "fa-duotone fa-calendar-circle-exclamation",
      "icon-color": _vm.computedDateAColor,
      "icon-size": _vm.fontSize
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.computedDateToDisplay) + " ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };