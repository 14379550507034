var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "hide-title-section": "",
      "modal-width": "60vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.selectedEntity ? _c('div', {
          staticClass: "c-grid mb-2"
        }, [_vm.isRoot ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info",
            "remove-margin-left": ""
          }
        }, [_vm._v(" This need date will be inherited by all children "), _c('i', [_vm._v("without")]), _vm._v(" a particularly specified need date. ")]), _vm.selectedEntity.computedNeedDate ? _c('div', {
          staticClass: "col-full mb-2"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Current need date being inherited"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.selectedEntity._computedNeedDate) + " ")])])], 1) : _vm._e(), _c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "label": "Need"
          },
          model: {
            value: _vm.formData.needDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "needDate", $$v);
            },
            expression: "formData.needDate"
          }
        })], 1)], 1) : _vm.selectedEntity.computedNeedDate ? _c('div', {
          staticClass: "col-full mb-2"
        }, [_vm.selectedEntity.computedNeedDate && !_vm.selectedEntity.needDate ? _c('AppBlockquote', {
          attrs: {
            "title": "Inherited need date"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.selectedEntity._computedNeedDate) + " ")])]) : _vm._e(), _c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "label": "Override need to"
          },
          model: {
            value: _vm.formData.needDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "needDate", $$v);
            },
            expression: "formData.needDate"
          }
        })], 1)], 1) : _c('div', [_c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppDate', {
          attrs: {
            "label": "Set need to"
          },
          model: {
            value: _vm.formData.needDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "needDate", $$v);
            },
            expression: "formData.needDate"
          }
        })], 1)])])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": !_vm.selectedEntity,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };