var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, Maybe } from '~/nasa_ui/types';
import { getAssemblyTemplate, getPartReferences, putAssemblyTemplate } from '~/nasa_ui/utils';
let ModalMegaPartEdit = class ModalMegaPartEdit extends Mixins(BaseModal) {
    autoHardwareList = null;
    autoHardwareListPartReferences = [];
    parentData = null;
    selectedEntity = null;
    selectedPartReferences = [];
    formData = {
        selectedDrawingNumber: null,
        selectedPartReference: null
    };
    assemblyTemplateId;
    get alertNoParts() {
        return this.autoHardwareList && !this.autoHardwareListPartReferences.length;
    }
    get autoHardwareListDrawingNumbers() {
        return this.autoHardwareList?.drawingNumberPrefix ?? [];
    }
    get selectOptionsAutoHardwareListPartReferences() {
        return this.autoHardwareListPartReferences
            .map((partReference) => ({
            displayText: partReference.serialNumber ?? 'No serial number',
            value: partReference.id
        }))
            .filter((partReference) => !this.selectedPartReferences.some((selectedPart) => selectedPart.id === partReference.value));
    }
    get selectOptionsDrawingNumber() {
        return this.autoHardwareListDrawingNumbers.map((drawingNumber) => ({
            displayText: drawingNumber,
            value: drawingNumber
        }));
    }
    get shouldDisablePartSelection() {
        return !this.selectOptionsAutoHardwareListPartReferences.length || this.isSaving;
    }
    async fetchAssemblyTemplate(assemblyTemplateId) {
        if (!assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(assemblyTemplateId);
            this.selectedEntity = resp.data ?? null;
            if (this.selectedEntity.parts?.length) {
                this.selectedPartReferences = this.selectedEntity.parts.map((p) => p.partReference);
            }
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Assembly Template'
            });
        }
    }
    async fetchAssemblyTemplateParent(parentId) {
        if (!parentId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(parentId);
            if (!resp) {
                return;
            }
            this.parentData = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchAutoHardwareList(autoHardwareListId) {
        if (!autoHardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/auto-hardware-lists/${autoHardwareListId}`);
            if (!resp.data) {
                throw new Error('Could not fetch the Auto Hardware List');
            }
            this.autoHardwareList = resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Auto Hardware List'
            });
        }
    }
    async fetchAutoHardwareListPartReferences() {
        if (!this.selectedEntity?.autoHardwareListId) {
            return;
        }
        try {
            const resp = await getPartReferences({
                autoHardwareListId: [this.selectedEntity.autoHardwareListId]
            });
            if (!resp.data) {
                throw new Error(`Failed to fetch parts from ${this.selectedEntity.autoHardwareListName ?? 'listing'}.`);
            }
            this.autoHardwareListPartReferences = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfAddPart() {
        if (!this.formData.selectedPartReference) {
            return;
        }
        const partReferenceId = this.formData.selectedPartReference;
        // wait for the next tick to ensure that the select options reset appropriately
        this.formData.selectedPartReference = null;
        this.$nextTick(() => {
            this.selectedPartReferences.push(this.autoHardwareListPartReferences.find((part) => part.id === partReferenceId));
        });
    }
    onClickOfClearPart(partReference) {
        this.selectedPartReferences = this.selectedPartReferences.filter((part) => part.id !== partReference.id);
    }
    async onClickOfSave() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await putAssemblyTemplate(this.selectedEntity.id, {
                ...this.selectedEntity,
                parts: this.selectedPartReferences.map((part) => {
                    return {
                        attributes: {},
                        partReferenceId: part.id
                    };
                })
            });
            if (!resp.data) {
                throw new Error('Could not save the part update.');
            }
            this.$notification.add({
                text: 'Part updated.',
                type: AlertType.SUCCESS
            });
            this.emitPartUpdated();
            this.emitModalClose();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    refreshSelectedEntity() {
        this.fetchAssemblyTemplate(this.assemblyTemplateId);
    }
    emitPartUpdated() { }
    async onAssemblyTemplateChange(val) {
        if (!val) {
            return;
        }
        await this.fetchAssemblyTemplate(val);
        if (this.selectedEntity?.parentId) {
            this.fetchAssemblyTemplateParent(this.selectedEntity.parentId);
        }
        if (this.selectedEntity?.autoHardwareListId) {
            this.fetchAutoHardwareList(this.selectedEntity.autoHardwareListId);
            this.fetchAutoHardwareListPartReferences();
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalMegaPartEdit.prototype, "assemblyTemplateId", void 0);
__decorate([
    Watch('formData.selectedPartReference'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalMegaPartEdit.prototype, "onClickOfAddPart", null);
__decorate([
    Emit('part-updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalMegaPartEdit.prototype, "emitPartUpdated", null);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ModalMegaPartEdit.prototype, "onAssemblyTemplateChange", null);
ModalMegaPartEdit = __decorate([
    Component
], ModalMegaPartEdit);
export default ModalMegaPartEdit;
