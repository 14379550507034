var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-primary-base)",
      "hide-title-section": "",
      "modal-width": "60vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.selectedEntity ? _c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-half"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.MegaAllocationTypeDisplay,
            "enum": _vm.MegaAllocationType,
            "clearable": "",
            "label": "MEGA allocation type"
          },
          model: {
            value: _vm.formData.attributes.megaAllocationType,
            callback: function ($$v) {
              _vm.$set(_vm.formData.attributes, "megaAllocationType", $$v);
            },
            expression: "formData.attributes.megaAllocationType"
          }
        })], 1)]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": !_vm.selectedEntity,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };