var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.shouldShowAttachmentForm && !_vm.selectedAttachment ? _c('ModalFileAttachmentCreate', {
    attrs: {
      "attachment-ids": _vm.attachmentIds,
      "read-only": _vm.readOnly,
      "work-order-step-id": _vm.workOrderStepId
    },
    on: {
      "modal-close": _vm.onModalClose,
      "saved": _vm.onStepAttachmentCreated
    }
  }) : _vm._e(), _vm.shouldShowAttachmentForm && _vm.selectedAttachment ? _c('ModalFileAttachmentEdit', {
    attrs: {
      "attachment-ids": _vm.attachmentIds,
      "entity": _vm.selectedAttachment,
      "read-only": _vm.readOnly,
      "work-order-step-id": _vm.workOrderStepId
    },
    on: {
      "modal-close": _vm.onModalClose,
      "saved": _vm.fetchAttachments
    }
  }) : _vm._e(), _vm.shouldShowDocumentAttachmentForm ? _c('ModalLinkDocumentAttachments', {
    attrs: {
      "document-id": _vm.documentId,
      "existing-ids": _vm.attachmentIds,
      "work-order-step-id": _vm.workOrderStepId
    },
    on: {
      "modal-close": _vm.onModalClose,
      "saved": _vm.fetchAttachments
    }
  }) : _vm._e(), _c('div', {
    staticClass: "c-flex align-items-center half-gap mb-2"
  }, [!_vm.readOnly ? _c('ButtonCreate', {
    staticClass: "ml-0",
    attrs: {
      "icon": "fad fa-file-plus"
    },
    on: {
      "click": function ($event) {
        _vm.shouldShowAttachmentForm = true;
      }
    }
  }, [_c('span', [_vm._v("Attach new file")])]) : _vm._e(), !_vm.readOnly && _vm.documentAttachmentIds.length ? _c('ButtonCreate', {
    staticClass: "ml-0",
    attrs: {
      "icon": "fad fa-file-plus"
    },
    on: {
      "click": function ($event) {
        _vm.shouldShowDocumentAttachmentForm = true;
      }
    }
  }, [_c('span', [_vm._v("Link Document attachment")])]) : _vm._e()], 1), _c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.attachment,
      "items": _vm.computedTableItems,
      "hide-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionInfo', {
          attrs: {
            "disabled": _vm.isReadOnly
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfTableRow(item);
            }
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };