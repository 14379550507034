var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.selectedEntity && _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('c-card', {
    attrs: {
      "start-collapsed": _vm.shouldInfoCardStartCollapsed,
      "border-color": "var(--v-documents-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
      "icon-color": "var(--v-documents-base)"
    }
  }), _c('span', [_vm._v("General information")])], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-third"
  }, [_c('AppText', {
    attrs: {
      "label": "Order number",
      "required": "",
      "upper-case": ""
    },
    model: {
      value: _vm.formData.number,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "number", $$v);
      },
      expression: "formData.number"
    }
  })], 1), _c('div', {
    staticClass: "col-half"
  }, [_c('AppText', {
    attrs: {
      "label": "Title",
      "clearable": ""
    },
    model: {
      value: _vm.formData.title,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppText', {
    attrs: {
      "label": "Revision"
    },
    model: {
      value: _vm.formData.overrideRevisionNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "overrideRevisionNumber", $$v);
      },
      expression: "formData.overrideRevisionNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-full mt-2"
  }, [_c('AppMarkdown', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c('div', {
    staticClass: "col-third"
  }, [_c('AppSelectContract', {
    model: {
      value: _vm.formData.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "contractNumber", $$v);
      },
      expression: "formData.contractNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-third"
  }, [_c('AppDate', {
    attrs: {
      "label": "Date of order"
    },
    model: {
      value: _vm.formData.overrideOpenDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "overrideOpenDate", $$v);
      },
      expression: "formData.overrideOpenDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third"
  }, [_c('AppDate', {
    attrs: {
      "label": "Contractual end"
    },
    model: {
      value: _vm.formData.estimatedCloseDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "estimatedCloseDate", $$v);
      },
      expression: "formData.estimatedCloseDate"
    }
  })], 1), _c('div', [_c('AppBlockquote', {
    attrs: {
      "title": "Next ECD"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._minProjectedCloseDate) + " ")])])], 1)]), _c('ButtonSave', {
    attrs: {
      "slot": "actions",
      "disabled": _vm.shouldDisableSaveButton,
      "isSaving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSave
    },
    slot: "actions"
  })], 1), _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-change_sets-base)",
      "start-collapsed": !_vm.lineItemOpenTableItems.length
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.change_sets,
      "icon-color": "var(--v-change_sets-base)"
    }
  }), _vm._v(" Open line items ("), _c('small', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.lineItemOpenTableItems.length)))]), _vm._v(") ")], 1), _c('ButtonCreate', {
    staticClass: "mb-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showModalCreateLineItem = true;
      }
    }
  }, [_vm._v(" Add line item ")]), _c('AppTableActions', {
    attrs: {
      "headers": _vm.lineItemOpenTableHeaders,
      "items": _vm.lineItemOpenTableItems,
      "show-header": false,
      "color": "change_sets",
      "dense-rows": ""
    },
    on: {
      "dblclick": _vm.uiClickOfViewLineItemInfo
    },
    scopedSlots: _vm._u([{
      key: "_deliveryItemsListNumberOrShipper",
      fn: function ({
        item
      }) {
        return [item.deliveryItemsListNumber ? _c('div', [_c('small', [_vm._v("DIL #")]), _c('span', {
          staticClass: "font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(item._deliveryItemsListNumber))])]) : _vm._e(), item.shipperNumber ? _c('div', [_c('small', [_vm._v("Shipper #")]), _c('span', {
          staticClass: "font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(item._shipperNumber))])]) : _vm._e(), !item.shipperNumber && !item.deliveryItemsListNumber ? _c('div', [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")]) : _vm._e()];
      }
    }, {
      key: "_lineItemPart",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "list__display"
        }, [_c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Drawing number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font text-truncate"
        }, [_vm._v(" " + _vm._s(item.partReference.drawingNumber || '-') + " ")])]), _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Serial number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(item.partReference.serialNumber || '-') + " ")])]), _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Location")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(item.partReference.location || '-') + " ")])])])];
      }
    }, {
      key: "_deliveryOrderLineItemStatus",
      fn: function ({
        item
      }) {
        return [item.status == _vm.ChangeSetStatus.APPROVED ? _c('span', [_vm._v("Completed")]) : _c('span', [_vm._v(_vm._s(item._deliveryOrderLineItemStatus))])];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('c-menu', {
          attrs: {
            "align-right": ""
          }
        }, [_c('ButtonMiniActionEllipsis', {
          attrs: {
            "slot": "activator",
            "icon-color": "var(--cosmic-text)"
          },
          slot: "activator"
        }), _c('c-menu-item', {
          attrs: {
            "icon": _vm.$icons.info_strong,
            "icon-color": "var(--v-info-base)",
            "icon-size": "18px"
          },
          on: {
            "c-click": function ($event) {
              return _vm.uiClickOfViewLineItemInfo(item);
            }
          }
        }, [_vm._v("View info")]), _c('c-menu-item', {
          attrs: {
            "icon": _vm.$icons.edit,
            "icon-size": "18px",
            "icon-color": "var(--v-primary-base)"
          },
          on: {
            "c-click": function ($event) {
              return _vm.uiClickOfEditLineItem(item);
            }
          }
        }, [_vm._v("Edit line item")]), item.status != _vm.ChangeSetStatus.APPROVED ? _c('c-menu-item', {
          attrs: {
            "icon": _vm.$icons.complete,
            "icon-size": "18px",
            "icon-color": "var(--v-success-base)"
          },
          on: {
            "c-click": function ($event) {
              return _vm.uiClickOfCompleteLineItem(item);
            }
          }
        }, [_vm._v("Complete line item")]) : _vm._e(), _c('c-menu-item', {
          attrs: {
            "icon": _vm.$icons.trash,
            "icon-size": "18px",
            "icon-color": "var(--v-error-base)"
          },
          on: {
            "c-click": function ($event) {
              return _vm.uiClickOfDeleteLineItem(item);
            }
          }
        }, [_vm._v("Delete line item")])], 1)];
      }
    }], null, false, 416030589)
  })], 1), _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-change_sets-base)",
      "start-collapsed": _vm.lineItemOpenTableItems.length
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.change_sets,
      "icon-color": "var(--v-change_sets-base)"
    }
  }), _vm._v(" Closed line items ("), _c('small', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.lineItemCloseTableItems.length)))]), _vm._v(") ")], 1), _c('AppTableActions', {
    attrs: {
      "headers": _vm.lineItemCloseTableHeaders,
      "items": _vm.lineItemCloseTableItems,
      "show-header": false,
      "color": "change_sets",
      "dense-rows": ""
    },
    on: {
      "dblclick": _vm.uiClickOfViewLineItemInfo
    },
    scopedSlots: _vm._u([{
      key: "_deliveryItemsListNumberOrShipper",
      fn: function ({
        item
      }) {
        return [item.deliveryItemsListNumber ? _c('div', [_c('small', [_vm._v("DIL #")]), _c('span', {
          staticClass: "font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(item._deliveryItemsListNumber))])]) : _vm._e(), item.shipperNumber ? _c('div', [_c('small', [_vm._v("Shipper #")]), _c('span', {
          staticClass: "font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(item._shipperNumber))])]) : _vm._e(), !item.shipperNumber && !item.deliveryItemsListNumber ? _c('div', [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")]) : _vm._e()];
      }
    }, {
      key: "_lineItemPart",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "list__display"
        }, [_c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Drawing number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font text-truncate"
        }, [_vm._v(" " + _vm._s(item.partReference.drawingNumber || '-') + " ")])]), _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Serial number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(item.partReference.serialNumber || '-') + " ")])]), _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Location")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(item.partReference.location || '-') + " ")])])])];
      }
    }, {
      key: "_deliveryOrderLineItemStatus",
      fn: function ({
        item
      }) {
        return [item.status == _vm.ChangeSetStatus.APPROVED ? _c('span', [_vm._v("Completed")]) : _c('span', [_vm._v(_vm._s(item._deliveryOrderLineItemStatus))])];
      }
    }, {
      key: "_contractEndingItemNumber",
      fn: function ({
        item
      }) {
        return [!item.autoHardwareListIds || !item.autoHardwareListIds.length ? _c('div', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")]) : _vm._e(), item.autoHardwareListIds && item.autoHardwareListIds.length === 1 ? _c('div', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._autoHardwareLists[0].name) + " ")]) : _vm._e(), _c('div', [item.autoHardwareListIds && item.autoHardwareListIds.length > 1 ? _c('ul', {
          staticClass: "px-0 py-2 list-style-none"
        }, _vm._l(item._autoHardwareLists, function (ahl) {
          return _c('li', {
            key: ahl.id
          }, [_c('span', {
            staticClass: "monospace_font"
          }, [_vm._v(_vm._s(ahl.name))])]);
        }), 0) : _vm._e()])];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('c-menu', {
          attrs: {
            "align-right": ""
          }
        }, [_c('ButtonMiniActionEllipsis', {
          attrs: {
            "slot": "activator",
            "icon-color": "var(--cosmic-text)"
          },
          slot: "activator"
        }), _c('c-menu-item', {
          attrs: {
            "icon": _vm.$icons.reopen,
            "icon-color": "var(--v-info-base)",
            "icon-size": "18px"
          },
          on: {
            "c-click": function ($event) {
              return _vm.uiClickOfReopenLineItemInfo(item);
            }
          }
        }, [_vm._v(" Reopen line item ")]), _c('c-menu-item', {
          attrs: {
            "icon": _vm.$icons.trash,
            "icon-size": "18px",
            "icon-color": "var(--v-error-base)"
          },
          on: {
            "c-click": function ($event) {
              return _vm.uiClickOfDeleteLineItem(item);
            }
          }
        }, [_vm._v("Delete line item")])], 1)];
      }
    }], null, false, 3264301053)
  })], 1), _vm.attachmentSelectOptions.length ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "collapsed": _vm.shouldAttachmentCardStartCollapsed,
      "border-color": "var(--cosmic-gray-darken-3)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.attachment,
      "icon-color": "var(--cosmic-gray-base)"
    }
  }), _vm._v(" Featured attachment ")], 1), _c('AppSelect', {
    staticStyle: {
      "display": "block",
      "max-width": "400px"
    },
    attrs: {
      "select-options": _vm.attachmentSelectOptions,
      "label": "Select an attachment to display here"
    },
    model: {
      value: _vm.selectedAttachmentIdToFeature,
      callback: function ($$v) {
        _vm.selectedAttachmentIdToFeature = $$v;
      },
      expression: "selectedAttachmentIdToFeature"
    }
  }), _vm.selectedAttachmentFileForPreview ? _c('AppFilePreview', {
    staticClass: "mt-3",
    attrs: {
      "file": _vm.selectedAttachmentFileForPreview,
      "allow-clear": "",
      "height": "600px",
      "pdf-width": "100%",
      "video-width": "800px",
      "width": "75%"
    },
    on: {
      "clear": function ($event) {
        _vm.selectedAttachmentIdToFeature = null;
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _vm.showModalCreateLineItem ? _c('ModalDeliveryOrderLineItemDetailsRequest', {
    key: "create-line-item",
    on: {
      "line-item": _vm.onAddLineItem,
      "modal-close": _vm.resetFromModals
    }
  }) : _vm._e(), _vm.showModalEditLineItem && _vm.selectedLineItem ? _c('ModalDeliveryOrderLineItemDetailsRequest', {
    key: "edit-line-item",
    attrs: {
      "line-item": _vm.selectedLineItem
    },
    on: {
      "line-item": _vm.onEditLineItem,
      "modal-close": _vm.resetFromModals
    }
  }) : _vm._e(), _vm.showModalCompleteLineItem && _vm.selectedLineItem ? _c('ModalDeliveryOrderLineItemComplete', {
    key: "complete-line-item",
    attrs: {
      "change-set-id": _vm.selectedLineItem.id,
      "delivery-order-id": _vm.selectedEntity.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showModalDeliveryOrderLineItemInfo && _vm.selectedLineItem ? _c('ModalDeliveryOrderLineItemInfo', {
    attrs: {
      "line-item": _vm.selectedLineItem
    },
    on: {
      "modal-close": _vm.resetFromModals
    }
  }) : _vm._e(), _vm.showModalDeleteLineItem && _vm.selectedLineItem && _vm.selectedLineItem.partReferenceId ? _c('AreYouSureDialog', {
    attrs: {
      "disabled": _vm.isSaving,
      "modal-width": "80vw"
    },
    on: {
      "affirmativeClicked": _vm.deleteLineItem,
      "negativeClicked": function ($event) {
        _vm.showModalDeleteLineItem = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "text",
      fn: function () {
        return [_vm._v(" This will delete every line item on this delivery order that matches the following part. Are you sure? ")];
      },
      proxy: true
    }, {
      key: "html",
      fn: function () {
        return [_c('AppPartReferenceDisplay', {
          attrs: {
            "part-reference-id": _vm.selectedLineItem.partReferenceId
          }
        })];
      },
      proxy: true
    }], null, false, 437520223)
  }) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };