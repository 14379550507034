import { __decorate } from "tslib";
import { isAfter, isBefore } from 'date-fns';
import { uniqBy } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import { MegaAllocationType } from '~/db_types';
import { DocumentType, EventType } from '~/db_types/swagger_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { AlertType } from '~/nasa_ui/types';
import { clearLogisticsCollectionCache, getLogisticsAllocationMatrix, putAssemblyTemplate, sortObjectBy } from '~/nasa_ui/utils';
import { dateFrom } from '~/nasa_ui/utils/dates';
import { transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AllocationMatrix = class AllocationMatrix extends Mixins(BaseVue) {
    currentlyHiddenMegas = [];
    dateFrom = dateFrom;
    DocumentType = DocumentType;
    EventType = EventType;
    isAfter = isAfter;
    isBefore = isBefore;
    isLoading = false;
    MegaAllocationType = MegaAllocationType;
    rawAllocationMatrix = null;
    selectedAssemblyTemplateToEdit = null;
    selectedMegaLeaf = null;
    showAllocationWindow = true;
    showDeliveryOrders = true;
    showArchivedMegas = false;
    showMegaAllocationTypeGround = true;
    showMegaAllocationTypeOnOrbit = true;
    showMegaAllocationTypeTradeStudy = false;
    showModalUpdateMegaAllocationType = false;
    showModalAssemblyTemplateInfo = false;
    showModalAutoHardwareListInfo = false;
    showModalCloneMega = false;
    showModalEventAllocationWindow = false;
    showModalMegaPartEdit = false;
    showModalUpdateNeed = false;
    showNeedDates = true;
    showOnlyCriticalLists = false;
    showPartReferenceLimitedLife = false;
    get filteredUniqAssemblyTemplateLeaves() {
        if (!this.showOnlyCriticalLists) {
            return this.uniqAssemblyTemplateLeaves;
        }
        return this.uniqAssemblyTemplateLeaves.filter((assemblyTemplate) => {
            return assemblyTemplate.autoHardwareListIsCritical;
        });
    }
    // These will be of type MEGA, super, etc and will be ROOTs
    // This is where the filtering happens
    get transformedAssemblyTemplates() {
        if (!this.rawAllocationMatrix || !this.rawAllocationMatrix.assemblyTemplates) {
            return [];
        }
        const transformed = transformAssemblyTemplateResponseWithChildrens(this.rawAllocationMatrix.assemblyTemplates);
        return transformed
            .filter((mega) => !this.currentlyHiddenMegas.map((mega) => mega.id).includes(mega.id))
            .filter((mega) => {
            if (!this.showMegaAllocationTypeGround &&
                !this.showMegaAllocationTypeOnOrbit &&
                !this.showMegaAllocationTypeTradeStudy) {
                return mega;
            }
            return ((this.showMegaAllocationTypeGround && mega.attributes.megaAllocationType === MegaAllocationType.GROUND) ||
                (this.showMegaAllocationTypeOnOrbit && mega.attributes.megaAllocationType === MegaAllocationType.ON_ORBIT) ||
                (this.showMegaAllocationTypeTradeStudy &&
                    mega.attributes.megaAllocationType === MegaAllocationType.TRADE_STUDY));
        })
            .filter((mega) => {
            // if you dont want to see the archived ones
            if ((!this.showArchivedMegas && !mega.attributes.isArchived) || !Object.hasOwn(mega.attributes, 'isArchived')) {
                return mega;
            }
            // if you want to see the inactives and this one is inactive, true
            if (this.showArchivedMegas && mega.attributes.isArchived) {
                return mega;
            }
            return false;
        });
    }
    get uniqAssemblyTemplateLeaves() {
        if (!this.transformedAssemblyTemplates) {
            return [];
        }
        return uniqBy(this.transformedAssemblyTemplates.flatMap((assemblyTemplate) => {
            return assemblyTemplate._children;
        }), '_name').sort(sortObjectBy('_name'));
    }
    created() {
        this.fetchAllocationMatrix();
    }
    generateChickletStatusCssClass(assemblyTemplate) {
        return assemblyTemplate?.attributes.chickletNodeStatus
            ? `chicklet_status__${assemblyTemplate?.attributes.chickletNodeStatus}`
            : '';
    }
    getChildTemplateFromRoot(rootMega, idToFind) {
        return rootMega._children?.find((child) => child.autoHardwareListId === idToFind) ?? null;
    }
    findAllocationWindowEvent(rootMega) {
        return rootMega._cosmicEvents.find((evt) => evt.subType === EventType.ALLOCATION_WINDOW) ?? null;
    }
    findChangeSetsByPartReference(partReference) {
        if (!this.rawAllocationMatrix) {
            return null;
        }
        return this.rawAllocationMatrix.deliveryOrderLineItems.changeSets?.filter((cs) => cs.partReferenceId === partReference.id);
    }
    findDeliveryOrdersByPartReference(partReference) {
        if (!this.rawAllocationMatrix) {
            return null;
        }
        const documentIdThatReferenceThisPart = this.findChangeSetsByPartReference(partReference)?.map((cs) => cs.documentId);
        if (!documentIdThatReferenceThisPart) {
            return this.rawAllocationMatrix.deliveryOrderLineItems.documents ?? null;
        }
        return this.rawAllocationMatrix.deliveryOrderLineItems.documents?.filter((doc) => {
            return documentIdThatReferenceThisPart?.includes(doc.id);
        });
    }
    async fetchAllocationMatrix() {
        try {
            this.isLoading = true;
            const resp = await getLogisticsAllocationMatrix();
            this.rawAllocationMatrix = resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
        finally {
            this.isLoading = false;
        }
    }
    hasAllocationWindow(rootMega) {
        return rootMega._cosmicEvents.find((evt) => evt.subType === EventType.ALLOCATION_WINDOW) !== undefined;
    }
    reset() {
        this.resetJustModals();
        clearLogisticsCollectionCache();
        this.fetchAllocationMatrix();
    }
    resetJustModals() {
        // this.shouldHideAssemblyTemplateSelectionOnApplyWaiverModal = false;
        // this.showModalAddCommentToMegaLeaf = false;
        // this.showModalAssemblyTemplateWaiver = false;
        this.showModalAutoHardwareListInfo = false;
        this.showModalCloneMega = false;
        // this.showModalDeliveryOrderLineItemInfo = false;
        this.showModalEventAllocationWindow = false;
        // this.showModalEventAssignLaunchReturn = false;
        // this.showModalEventLaunch = false;
        // this.showModalEventReturn = false;
        this.showModalMegaPartEdit = false;
        this.showModalUpdateNeed = false;
        this.showModalUpdateMegaAllocationType = false;
        // this.currentlySelectedLaunchReturnEvents = [];
        this.selectedAssemblyTemplateToEdit = null;
        // this.selectedCosmicEvent = null;
        // this.selectedChangeSet = null;
        this.selectedMegaLeaf = null;
    }
    uiClickOfAllocationWindow(item) {
        this.showAllocationWindow = true;
        this.showModalEventAllocationWindow = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfCloneMega(item) {
        this.showModalCloneMega = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfEditAssemblyTemplatePart(item) {
        this.selectedAssemblyTemplateToEdit = item;
        this.showModalMegaPartEdit = true;
    }
    uiClickOfHideMega(item) {
        this.currentlyHiddenMegas.push(item);
    }
    uiClickOfUnhideMegas() {
        this.currentlyHiddenMegas = [];
    }
    uiClickOfUpdateNeed(item) {
        this.showModalUpdateNeed = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfUpdateMegaAllocationType(item) {
        this.showModalUpdateMegaAllocationType = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfViewAutoHardwareListInfo(item) {
        this.showModalAutoHardwareListInfo = true;
        this.selectedMegaLeaf = item;
    }
    async onClickOfArchiveMega(item) {
        if (!item) {
            return;
        }
        try {
            const body = {
                attributes: Object.assign({}, item.attributes, { isArchived: true }),
                autoHardwareListId: item.autoHardwareListId ?? null,
                digitalLibraryRecordId: item.digitalLibraryRecordId ?? null,
                documentId: item.documentId ?? null,
                installedOn: item.installedOn,
                maxQuantity: item.maxQuantity,
                minQuantity: item.minQuantity,
                name: item.name ?? null,
                needDate: item.needDate ?? null,
                parentId: item.parentId ?? null,
                parts: item.parts ?? null,
                pbsItemId: item.pbsItemId ?? null,
                sequence: item.sequence,
                subType: item.subType,
                waiverDate: item.waiverDate ?? null,
                waiverNumber: item.waiverNumber ?? null
            };
            const resp = await putAssemblyTemplate(item.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Archived.'
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfUnarchiveMega(item) {
        if (!item) {
            return;
        }
        try {
            const body = {
                attributes: Object.assign({}, item.attributes, { isArchived: false }),
                autoHardwareListId: item.autoHardwareListId ?? null,
                digitalLibraryRecordId: item.digitalLibraryRecordId ?? null,
                documentId: item.documentId ?? null,
                installedOn: item.installedOn,
                maxQuantity: item.maxQuantity,
                minQuantity: item.minQuantity,
                name: item.name ?? null,
                needDate: item.needDate ?? null,
                parentId: item.parentId ?? null,
                parts: item.parts ?? null,
                pbsItemId: item.pbsItemId ?? null,
                sequence: item.sequence,
                subType: item.subType,
                waiverDate: item.waiverDate ?? null,
                waiverNumber: item.waiverNumber ?? null
            };
            const resp = await putAssemblyTemplate(item.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Unarchived.'
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
AllocationMatrix = __decorate([
    Component
], AllocationMatrix);
export default AllocationMatrix;
