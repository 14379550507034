var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-assembly_templates-base)",
      "hide-title-section": "",
      "modal-width": "75vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.autoHardwareList ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Name",
            "monospace": "",
            "value": _vm.autoHardwareList.name
          }
        })], 1), _c('div', {
          staticClass: "col-two-thirds"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description",
            "value": _vm.autoHardwareList.description
          }
        })], 1), _vm.autoHardwareListDrawingNumbers.length ? _c('div', {
          staticClass: "col-third"
        }, [_vm.autoHardwareListDrawingNumbers.length > 1 ? _c('AppSelect', {
          attrs: {
            "label": "Drawing number",
            "select-options": _vm.selectOptionsDrawingNumber
          },
          model: {
            value: _vm.formData.selectedDrawingNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedDrawingNumber", $$v);
            },
            expression: "formData.selectedDrawingNumber"
          }
        }) : _c('AppBlockquote', {
          attrs: {
            "title": "Drawing numbers",
            "monospace": "",
            "value": _vm.autoHardwareListDrawingNumbers[0]
          }
        })], 1) : _vm._e()]) : _vm._e(), _vm.selectedPartReferences.length ? _c('div', {
          staticClass: "c-grid mb-2"
        }, _vm._l(_vm.selectedPartReferences, function (part) {
          return _c('div', {
            key: part.id,
            staticClass: "col-full mb-2"
          }, [_c('AppPartReferenceDisplay', {
            staticClass: "part_display",
            attrs: {
              "part-reference-id": part.id
            }
          }), _c('ButtonClearSelection', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function ($event) {
                return _vm.onClickOfClearPart(part);
              }
            }
          }, [_vm._v("Clear part selection")])], 1);
        }), 0) : _vm._e(), _vm.selectedPartReferences.length ? _c('hr') : _vm._e(), _c('div', {
          staticClass: "c-grid my-2"
        }, [_vm.alertNoParts && _vm.autoHardwareList ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "warning",
            "remove-margin-left": ""
          }
        }, [_vm._v(" No parts were found on list "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.autoHardwareList.name))]), _vm._v(". ")])], 1) : _vm.selectOptionsAutoHardwareListPartReferences.length ? _c('div', {
          staticClass: "col-half c-flex align-items-end"
        }, [_c('AppSelect', {
          staticClass: "part_select",
          attrs: {
            "disabled": _vm.shouldDisablePartSelection,
            "select-options": _vm.selectOptionsAutoHardwareListPartReferences,
            "label": "Other parts available by serial",
            "monospace-selections": ""
          },
          model: {
            value: _vm.formData.selectedPartReference,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedPartReference", $$v);
            },
            expression: "formData.selectedPartReference"
          }
        })], 1) : _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "warning",
            "remove-margin-left": ""
          }
        }, [_vm._v(" All parts selected. ")])], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.alertNoParts,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };