var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container fluid pa-0"
  }, [!_vm.selectedDocument ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e(), _vm.isReadOnly && !_vm.steps.length && !_vm.isFetching ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_vm._v(" Document is in "), _c('b', [_vm._v("read-only")]), _vm._v(" mode and no steps have been created. ")]) : _vm._e(), _c('WorkOrderStepsActionBar', {
    attrs: {
      "read-only": _vm.isReadOnly,
      "steps": _vm.steps
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonMiniActionCreate', {
          attrs: {
            "tooltip": _vm.isDocumentOpenForEdits ? 'Add a new step.' : 'Document must be uninitialized to add steps.',
            "disabled": _vm.shouldDisableCreateButton,
            "tooltip-position": "bottom left"
          },
          on: {
            "click": _vm.onClickOfCreateStepButton
          }
        }), _c('ButtonMiniActionEdit', {
          attrs: {
            "tooltip": _vm.isDocumentOpenForEdits ? 'Edit current step.' : 'Document must be uninitialized to edit.',
            "disabled": _vm.shouldDisableEditButton,
            "tooltip-position": "bottom"
          },
          on: {
            "click": _vm.onClickOfEditButton
          }
        }), _vm.currentViewingStep && !_vm.currentViewingStep.isEditable ? _c('ButtonMiniAction', {
          attrs: {
            "icon": "fad fa-undo",
            "icon-color": "var(--v-warning-darken4)",
            "disabled": !_vm.canReopenStep,
            "tooltip": _vm.canReopenStep ? 'Reset Approvals.' : 'Approvals cannot be reset.'
          },
          on: {
            "click": _vm.onClickOfReopenStepButton
          }
        }) : _vm._e(), _vm.currentViewingStep ? _c('ButtonMiniAction', {
          attrs: {
            "disabled": _vm.currentViewingStepComplete || !_vm.isDocumentApproved || !_vm.currentUserCanApproveStep,
            "tooltip": _vm.tooltipApproveStep
          },
          on: {
            "click": _vm.onClickOfCompleteStepButton
          }
        }, [[_c('c-icon-fa', {
          attrs: {
            "icon": "fad fa-thumbs-up",
            "icon-color": "var(--v-success-base)"
          }
        }), _c('c-icon-fa', {
          staticClass: "stacked_fa_icon",
          attrs: {
            "icon": _vm.$icons.circle_check,
            "icon-color": "var(--v-success-base)"
          }
        })]], 2) : _vm._e(), _vm.currentViewingStep ? _c('ButtonMiniAction', {
          attrs: {
            "disabled": _vm.currentViewingStepComplete || !_vm.isDocumentApproved || !_vm.currentUserCanRejectStep,
            "tooltip": _vm.tooltipRejectStep,
            "icon": "fad fa-thumbs-down",
            "icon-color": "var(--v-secondary-base)"
          },
          on: {
            "click": _vm.onClickOfRejectStepButton
          }
        }) : _vm._e(), _c('ButtonMiniActionDelete', {
          attrs: {
            "disabled": !_vm.isDocumentOpenForEdits || _vm.isReadOnly,
            "tooltip": _vm.isDocumentOpenForEdits ? 'Delete this step.' : 'Document must be uninitialized to delete.',
            "tooltip-position": "bottom"
          },
          on: {
            "click": _vm.onClickOfDeleteStep
          }
        }), _c('ButtonMiniAction', {
          staticClass: "mr-4",
          attrs: {
            "disabled": !_vm.isDocumentOpenForEdits || _vm.isReadOnly,
            "tooltip": "Import spreadsheet",
            "icon": "fad fa-file-excel",
            "icon-color": "var(--v-success-base)"
          },
          on: {
            "click": _vm.onClickOfImportSpreadsheet
          }
        }), _c('ButtonMiniAction', {
          staticClass: "mr-4",
          attrs: {
            "tooltip": "Export to PDF",
            "icon": _vm.$icons['pdf']
          },
          on: {
            "click": _vm.onClickOfExportToPdfButton
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentViewingStepIndex,
      callback: function ($$v) {
        _vm.currentViewingStepIndex = $$v;
      },
      expression: "currentViewingStepIndex"
    }
  }), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    staticClass: "steps_container"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldDisableCreateButton && !_vm.steps.length && !_vm.isFetching ? _c('div', [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.INFO
    }
  }, [_c('p', [_vm._v("This Document has no steps.")])])], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentViewingStep ? _c('div', {
    key: "step",
    staticClass: "step"
  }, [_c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentViewingStepComplete ? _c('div', {
    staticClass: "step_completed"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.pipeline_complete
    }
  }), _c('span', [_vm._v("Step complete.")])], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentViewingStepRejected ? _c('div', {
    staticClass: "step_rejected"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.pipeline_rejected
    }
  }), _c('span', [_vm._v("Step rejected.")])], 1) : _vm._e()]), _c('div', {
    staticClass: "step_guts",
    class: {
      completed: _vm.currentViewingStepComplete,
      rejected: _vm.currentViewingStepRejected
    }
  }, [_c('div', {
    staticClass: "alert_container"
  }, [_vm.currentViewingStep.warning ? _c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentViewingStep.warning) + " ")]) : _vm._e(), _vm.currentViewingStep.caution ? _c('Alert', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentViewingStep.caution) + " ")]) : _vm._e(), _vm.currentViewingStep.notes ? _c('AppPageDescription', [_vm._v(" " + _vm._s(_vm.currentViewingStep.notes) + " ")]) : _vm._e()], 1), _vm.currentViewingStep.action ? _c('div', {
    staticClass: "c-grid my-5"
  }, [_c('div', {
    staticClass: "col-half offset-3"
  }, [_c('AppChip', {
    staticClass: "step_procedure_action_chip",
    attrs: {
      "chips": [{
        text: 'Action to be taken',
        color: _vm.EntityType.DOCUMENT,
        outline: true
      }]
    }
  }), _c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.currentViewingStep.action,
      "maxHeight": "auto"
    }
  })], 1)]) : _vm._e(), _vm.selectedDocument ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-half center"
  }, [_c('StepDetailsApprovals', {
    attrs: {
      "active-authorities": _vm.activeAuthorities,
      "doc-type": _vm.selectedDocument.subType
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [!_vm.currentViewingStepComplete && _vm.isDocumentApproved && _vm.currentUserCanApproveStep ? _c('ButtonGeneric', {
          attrs: {
            "icon": "fad fa-thumbs-up",
            "color": "success"
          },
          on: {
            "click": _vm.onClickOfCompleteStepButton
          }
        }, [_vm._v(" Approve ")]) : _vm._e(), !_vm.currentViewingStepComplete && _vm.isDocumentApproved && _vm.currentUserCanRejectStep ? _c('ButtonGeneric', {
          attrs: {
            "icon": "fad fa-thumbs-down",
            "color": "error"
          },
          on: {
            "click": _vm.onClickOfRejectStepButton
          }
        }, [_vm._v(" Reject ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 282832958)
  })], 1)]) : _vm._e(), _vm.currentViewingStep.hardwareList ? _c('div', {
    staticClass: "mt-2"
  }, [_c('WorkOrderStepHardware', {
    attrs: {
      "hardware-list-id": _vm.currentViewingStep.hardwareList.id
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mt-2"
  }, [_c('WorkOrderStepTools', {
    attrs: {
      "document": _vm.selectedDocument,
      "step": _vm.currentViewingStep
    }
  })], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('c-card', {
    attrs: {
      "border-color": "var(--cosmic-gray-base)",
      "start-collapsed": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "slot": "icon",
      "icon": _vm.$icons.attachment,
      "icon-color": "var(--cosmic-gray-base)"
    },
    slot: "icon"
  }), _c('span', {
    staticClass: "expansion_override_title"
  }, [_vm._v("Attachments")])], 1), _c('WorkOrderStepAttachments', {
    attrs: {
      "work-order-step-id": _vm.currentViewingStep.id,
      "read-only": !_vm.isDocumentOpenForEdits
    }
  })], 1)], 1), _vm.currentHistoricalChangeSetAuthorities ? _c('v-layout', {
    attrs: {
      "mt-5": "",
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "text-xs-right",
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonGeneric', {
    staticClass: "mr-0",
    attrs: {
      "tooltip": "More info",
      "tooltip-position": "top right",
      "icon": _vm.$icons.ellipsis
    },
    on: {
      "click": function ($event) {
        _vm.showMoreInfo = !_vm.showMoreInfo;
      }
    }
  })], 1), _c('v-slide-x-transition', [_vm.showMoreInfo ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "mb-3 darken-2",
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.StepApprovalTableHeaders,
      "items": _vm.currentHistoricalChangeSetAuthorities,
      "disable-initial-sort": "",
      "icon": "fa fa-balance-scale",
      "title": "Historical Approvals"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1) : _c('div', {
    staticClass: "no_current_step"
  }, [_vm.selectedDocument && !_vm.isReadOnly && !_vm.shouldDisableCreateButton ? _c('WorkOrderStepsGettingStarted', {
    attrs: {
      "document-id": _vm.id,
      "document-type": _vm.selectedDocument.subType,
      "read-only": _vm.isReadOnly
    },
    on: {
      "created": _vm.onModalWorkOrderStepCreateAndEditClose
    }
  }) : _vm._e()], 1)])], 1)]), _vm.showNewWorkOrderStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "document-id": _vm.id,
      "default-step-number": _vm.stepsDefault,
      "max-step-number": _vm.stepsMax,
      "read-only": _vm.isReadOnly,
      "work-order-step-to-edit-id": _vm.workOrderStepToEditId
    },
    on: {
      "modal-close": function ($event) {
        _vm.showNewWorkOrderStepModal = false;
      },
      "step-saved": _vm.onModalWorkOrderStepCreateAndEditClose
    }
  }) : _vm._e(), _vm.showCompleteStepModal ? _c('ModalWorkOrderStepComplete', {
    attrs: {
      "document-id": _vm.id,
      "change-set-ids": _vm.changeSetIdsToApprove,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCompleteStepModal = false;
      }
    }
  }) : _vm._e(), _vm.currentViewingStep && _vm.showRejectStepModal ? _c('ModalWorkOrderStepReject', {
    attrs: {
      "change-set-id": _vm.currentViewingStep.id,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showRejectStepModal = false;
      },
      "step-rejected": _vm.onWorkOrderStepReject
    }
  }) : _vm._e(), _vm.currentViewingStep && _vm.showDeleteStepModal ? _c('ModalWorkOrderStepDelete', {
    attrs: {
      "change-set-id": _vm.currentViewingStep.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showDeleteStepModal = false;
      },
      "step-deleted": _vm.onWorkOrderStepDelete
    }
  }) : _vm._e(), _vm.selectedDocument && _vm.showImportSpreadsheetModal ? _c('ModalWorkOrderStepImportSpreadsheet', {
    attrs: {
      "document-id": _vm.id,
      "document-type": _vm.selectedDocument.subType,
      "starting-step": _vm.stepsDefault
    },
    on: {
      "modal-close": function ($event) {
        _vm.showImportSpreadsheetModal = false;
      },
      "created": _vm.onImportSpreadsheetCompletion
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };