var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "c-grid allocation_matrix"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "col-full"
  }, [_c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.INVENTORY
    }
  })], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isLoading ? _c('div', {
    staticClass: "col-full allocation_matrix_home"
  }, [_c('table', [_c('thead', [_c('tr', {
    staticStyle: {
      "position": "sticky",
      "top": "0",
      "left": "0",
      "z-index": "3"
    }
  }, [_c('th', [_c('div', {
    staticClass: "c-flex align-center",
    staticStyle: {
      "gap": "0.8rem",
      "flex-wrap": "wrap"
    }
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showOnlyCriticalLists
    },
    attrs: {
      "icon": _vm.$icons['CRITICAL_INVENTORY_TRACKING'],
      "tooltip": _vm.showOnlyCriticalLists ? 'Show all lists' : 'Show only critical lists',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showOnlyCriticalLists = !_vm.showOnlyCriticalLists;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showNeedDates
    },
    attrs: {
      "icon": _vm.$icons.need,
      "tooltip": _vm.showNeedDates ? 'Hide need dates' : 'Show need dates',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showNeedDates = !_vm.showNeedDates;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showAllocationWindow
    },
    attrs: {
      "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
      "tooltip": _vm.showAllocationWindow ? 'Hide Allocation window' : 'Show Allocation window',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showAllocationWindow = !_vm.showAllocationWindow;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showDeliveryOrders
    },
    attrs: {
      "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
      "tooltip": _vm.showDeliveryOrders ? 'Hide Delivery orders' : 'Show Delivery orders',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showDeliveryOrders = !_vm.showDeliveryOrders;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showPartReferenceLimitedLife
    },
    attrs: {
      "icon": _vm.$icons.limited_life,
      "tooltip": _vm.showPartReferenceLimitedLife ? 'Hide limited life' : 'Show limited life',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showPartReferenceLimitedLife = !_vm.showPartReferenceLimitedLife;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showMegaAllocationTypeGround
    },
    attrs: {
      "icon": _vm.$icons[_vm.MegaAllocationType.GROUND],
      "tooltip": _vm.showMegaAllocationTypeGround ? 'Hide MEGA allocations marked as GROUND' : 'Show MEGA allocations marked as GROUND',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showMegaAllocationTypeGround = !_vm.showMegaAllocationTypeGround;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showMegaAllocationTypeOnOrbit
    },
    attrs: {
      "icon": _vm.$icons[_vm.MegaAllocationType.ON_ORBIT],
      "tooltip": _vm.showMegaAllocationTypeOnOrbit ? 'Hide MEGA allocations marked as ON ORBIT' : 'Show MEGA allocations marked as ON ORBIT',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showMegaAllocationTypeOnOrbit = !_vm.showMegaAllocationTypeOnOrbit;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showMegaAllocationTypeTradeStudy
    },
    attrs: {
      "icon": _vm.$icons[_vm.MegaAllocationType.TRADE_STUDY],
      "tooltip": _vm.showMegaAllocationTypeTradeStudy ? 'Hide MEGA allocations marked as TRADE STUDY' : 'Show MEGA allocations marked as TRADE STUDY',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showMegaAllocationTypeTradeStudy = !_vm.showMegaAllocationTypeTradeStudy;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showArchivedMegas
    },
    attrs: {
      "icon": _vm.$icons.archived,
      "tooltip": _vm.showArchivedMegas ? 'Hide archived MEGAs' : 'Show archived MEGAs',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showArchivedMegas = !_vm.showArchivedMegas;
      }
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentlyHiddenMegas.length ? _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    attrs: {
      "icon": "fa-regular fa-eye",
      "tooltip": `Unhide ${_vm.currentlyHiddenMegas.length} MEGAs`,
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.uiClickOfUnhideMegas
    }
  })], 1) : _vm._e()])], 1)]), _vm._l(_vm.transformedAssemblyTemplates, function (rootMegaAssemblyTemplate) {
    return _c('th', {
      key: rootMegaAssemblyTemplate.id,
      staticClass: "allocation_matrix__table_col_header"
    }, [_c('div', {
      staticStyle: {
        "position": "relative"
      }
    }, [_c('router-link', {
      staticClass: "monospace_font font-weight-bold",
      attrs: {
        "to": `/mega_allocation/${rootMegaAssemblyTemplate.id}`
      }
    }, [_vm._v(" " + _vm._s(rootMegaAssemblyTemplate._name) + " ")]), _c('v-slide-x-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [rootMegaAssemblyTemplate.attributes.isArchived ? _c('div', {
      staticClass: "mega_archived secondary white--text font-weight-bold text-xs-left px-2 py-1"
    }, [_c('c-icon-fa', {
      staticClass: "mr-2",
      attrs: {
        "icon": _vm.$icons.archived,
        "icon-size": "16px",
        "icon-color": "white"
      }
    }), _c('span', [_vm._v("Archived")])], 1) : _vm._e()]), _c('div', {
      staticClass: "mega_allocation_type"
    }, [!rootMegaAssemblyTemplate.attributes.megaAllocationType ? _c('i', {
      staticClass: "font-weight-regular error--text"
    }, [_vm._v(" no allocation type set ")]) : _c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons[rootMegaAssemblyTemplate.attributes.megaAllocationType],
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)",
        "tooltip": `MEGA allocation type is ${rootMegaAssemblyTemplate.attributes.megaAllocationType}`
      }
    })], 1), _c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_vm.showNeedDates ? _c('div', {
      staticClass: "need_date"
    }, [!rootMegaAssemblyTemplate.computedNeedDate ? _c('i', {
      staticClass: "font-weight-regular error--text"
    }, [_vm._v(" no need set ")]) : _c('span', [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons.need,
        "icon-size": "12px",
        "icon-color": "var(--v-movement-base)"
      }
    }), _vm._v(" " + _vm._s(rootMegaAssemblyTemplate._computedNeedDate) + " ")], 1)]) : _vm._e()]), _c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_vm.showAllocationWindow ? _c('div', {
      staticClass: "allocation_window"
    }, [!_vm.findAllocationWindowEvent(rootMegaAssemblyTemplate) ? _c('i', {
      staticClass: "font-weight-regular error--text"
    }, [_vm._v(" no window assigned ")]) : _c('span', [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
        "icon-size": "12px",
        "icon-color": "var(--v-events-base)"
      }
    }), _vm._v(" " + _vm._s(_vm.findAllocationWindowEvent(rootMegaAssemblyTemplate)._startDate) + " "), _c('span', {
      staticClass: "font-weight-regular"
    }, [_vm._v("to")]), _vm._v(" " + _vm._s(_vm.findAllocationWindowEvent(rootMegaAssemblyTemplate)._endDate) + " ")], 1)]) : _vm._e()]), _c('div', {
      staticClass: "actions",
      staticStyle: {
        "position": "absolute",
        "top": "0",
        "right": "0.1em"
      }
    }, [_c('c-menu', {
      attrs: {
        "align-right": ""
      }
    }, [_c('ButtonMiniActionEllipsis', {
      attrs: {
        "slot": "activator",
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      slot: "activator"
    }), _c('c-menu-item', {
      attrs: {
        "icon": "fa-duotone fa-user-astronaut",
        "href": `/mega_allocation/${rootMegaAssemblyTemplate.id}`,
        "icon-size": "18px"
      }
    }, [_vm._v(" Detailed MEGA view ")]), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.application_reporting,
        "href": `/reporting/mega_deliveries/${rootMegaAssemblyTemplate.id}`,
        "icon-size": "18px"
      }
    }, [_vm._v(" MEGA Deliveries report ")]), _c('hr'), !_vm.hasAllocationWindow(rootMegaAssemblyTemplate) ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
        "icon-color": "var(--v-events-base)",
        "icon-size": "18px"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfAllocationWindow(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Create an Allocation window ")]) : _vm.hasAllocationWindow(rootMegaAssemblyTemplate) ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
        "icon-color": "var(--v-events-base)",
        "icon-size": "18px"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfAllocationWindow(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Update Allocation window ")]) : _vm._e(), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.need,
        "icon-size": "18px",
        "icon-color": "var(--v-delivery_matrix_items-base)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfUpdateNeed(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Update need date ")]), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.edit,
        "icon-size": "18px",
        "icon-color": "var(--v-primary-base)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfUpdateMegaAllocationType(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Update MEGA allocation type ")]), _c('hr'), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.clone,
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfCloneMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Clone "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(rootMegaAssemblyTemplate.name))])]), !rootMegaAssemblyTemplate.attributes.isArchived ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.archived,
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.onClickOfArchiveMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Archive "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(rootMegaAssemblyTemplate.name))])]) : _vm._e(), rootMegaAssemblyTemplate.attributes.isArchived ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.unarchive,
        "icon-size": "18px",
        "icon-color": "var(--v-secondary-base)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.onClickOfUnarchiveMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Unarchive "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(rootMegaAssemblyTemplate.name))])]) : _vm._e(), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.clear,
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfHideMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Hide "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(rootMegaAssemblyTemplate.name))])])], 1)], 1)], 1)]);
  })], 2)]), _c('tbody', _vm._l(_vm.filteredUniqAssemblyTemplateLeaves, function (leaf) {
    return _c('tr', {
      key: leaf.id,
      staticClass: "allocation_matrix__table_row"
    }, [_c('th', {
      staticClass: "allocation_matrix__table_row_header auto_hardware_list_view_information",
      attrs: {
        "tooltip": `${leaf._autoHardwareListName}: ${leaf._autoHardwareListDescription}
                
Double click to view item list information
`,
        "tooltip-position": "right"
      },
      on: {
        "dblclick": function ($event) {
          return _vm.uiClickOfViewAutoHardwareListInfo(leaf);
        }
      }
    }, [_c('div', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(leaf._autoHardwareListName))]), _c('div', {
      staticClass: "text-truncate description"
    }, [_vm._v(_vm._s(leaf._autoHardwareListDescription))]), leaf.autoHardwareListIsCritical ? _c('div', [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons['CRITICAL_INVENTORY_TRACKING'],
        "icon-size": "12px",
        "icon-color": "var(--cosmic-text)",
        "tooltip": "List is marked critical",
        "tooltip-position": "bottom left"
      }
    })], 1) : _vm._e()]), _vm._l(_vm.transformedAssemblyTemplates, function (rootMegaAssemblyTemplate) {
      return _c('td', {
        key: rootMegaAssemblyTemplate.id,
        staticClass: "pa-1",
        class: _vm.generateChickletStatusCssClass(rootMegaAssemblyTemplate)
      }, [_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId) && _vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts.length === 0 ? _c('div', {
        staticClass: "no_part_information_entered",
        attrs: {
          "tooltip": "Double click to select a part"
        },
        on: {
          "dblclick": function ($event) {
            _vm.uiClickOfEditAssemblyTemplatePart(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId));
          }
        }
      }, [_c('i', [_vm._v("No part information entered")])]) : _vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId) && _vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts.length === 1 ? _c('div', {
        staticClass: "list__display hover_cursor",
        attrs: {
          "tooltip": "Double click to swap parts"
        },
        on: {
          "dblclick": function ($event) {
            _vm.uiClickOfEditAssemblyTemplatePart(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId));
          }
        }
      }, [_c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Drawing number")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font text-truncate"
      }, [_vm._v(" " + _vm._s(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.drawingNumber || '-') + " ")])]), _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Serial number")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font text-truncate"
      }, [_vm._v(" " + _vm._s(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.serialNumber || '-') + " ")])]), _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Location")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font text-truncate"
      }, [_vm._v(" " + _vm._s(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.location || '-') + " ")])]), _c('v-slide-x-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.birthDate && _vm.showPartReferenceLimitedLife ? _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Birth")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font"
      }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.birthDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.calibrationNumber && _vm.showPartReferenceLimitedLife ? _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Calibration number")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font"
      }, [_vm._v(" " + _vm._s(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.calibrationNumber) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.lastCalibrationDate && _vm.showPartReferenceLimitedLife ? _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Last calibration")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font"
      }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.lastCalibrationDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.shelfLifeExpirationDate && _vm.showPartReferenceLimitedLife ? _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Shelf life expiry")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font"
      }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.shelfLifeExpirationDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.usageLifeExpirationDate && _vm.showPartReferenceLimitedLife ? _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Usage expiration")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font"
      }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.usageLifeExpirationDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.showNeedDates ? _c('div', {
        staticClass: "list__item"
      }, [_c('p', {
        staticClass: "list__label"
      }, [_vm._v("Need")]), _c('p', {
        staticClass: "list__value pl-0 font-weight-bold monospace_font"
      }, [_vm._v(" " + _vm._s(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.computedNeedDate ? _vm.$dateDisplay(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts[0].partReference.computedNeedDate) : '-') + " ")])]) : _vm._e()])], 1) : _vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId) ? _c('div', {
        staticClass: "hover_cursor",
        attrs: {
          "tooltip": "Double click to update part selection"
        },
        on: {
          "dblclick": function ($event) {
            _vm.uiClickOfEditAssemblyTemplatePart(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId));
          }
        }
      }, _vm._l(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts, function (part) {
        return _c('div', {
          key: part.id
        }, [_c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Drawing number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(part.partReference.drawingNumber || '-') + " ")])]), _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Serial number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(part.partReference.serialNumber || '-') + " ")])]), _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Location")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(part.partReference.location || '-') + " ")])]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [part.partReference.birthDate && _vm.showPartReferenceLimitedLife ? _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Birth")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.birthDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [part.partReference.calibrationNumber && _vm.showPartReferenceLimitedLife ? _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Calibration number")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(part.partReference.calibrationNumber) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [part.partReference.lastCalibrationDate && _vm.showPartReferenceLimitedLife ? _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Last calibration")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.lastCalibrationDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [part.partReference.shelfLifeExpirationDate && _vm.showPartReferenceLimitedLife ? _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Shelf life expiry")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.shelfLifeExpirationDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [part.partReference.usageLifeExpirationDate && _vm.showPartReferenceLimitedLife ? _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Usage expiration")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.usageLifeExpirationDate)) + " ")])]) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showNeedDates ? _c('div', {
          staticClass: "list__item"
        }, [_c('p', {
          staticClass: "list__label"
        }, [_vm._v("Need")]), _c('p', {
          staticClass: "list__value pl-0 font-weight-bold monospace_font"
        }, [_vm._v(" " + _vm._s(part.partReference.computedNeedDate ? _vm.$dateDisplay(part.partReference.computedNeedDate) : '-') + " ")])]) : _vm._e()]), _c('hr', {
          staticClass: "mb-2"
        })], 1);
      }), 0) : _vm._e(), _c('v-slide-y-transition', {
        attrs: {
          "hide-on-leave": ""
        }
      }, [_vm.showDeliveryOrders && _vm.rawAllocationMatrix && _vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId) && _vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts.length ? _vm._l(_vm.getChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts, function (astPart) {
        return _c('div', {
          key: astPart.id
        }, _vm._l(_vm.findDeliveryOrdersByPartReference(astPart.partReference), function (deliveryOrderDocument) {
          return _c('div', {
            key: deliveryOrderDocument.id
          }, [_c('div', {
            staticClass: "list__item delivery_order"
          }, [_c('c-icon-fa', {
            staticClass: "mr-2",
            attrs: {
              "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
              "icon-color": "white",
              "icon-size": "12px"
            }
          }), _c('p', {
            staticClass: "list__label monospace_font"
          }, [_vm._v(_vm._s(deliveryOrderDocument.number))]), deliveryOrderDocument.minProjectedCloseDate ? _c('p', {
            staticClass: "list__value pl-0 font-weight-bold monospace_font important_date",
            attrs: {
              "tooltip": "This is the Delivery Order's minimum projected close date (ECD) out of each line item."
            }
          }, [_vm._v(" " + _vm._s(deliveryOrderDocument.minProjectedCloseDate) + " ")]) : _vm._e(), deliveryOrderDocument.estimatedCloseDate ? _c('p', {
            staticClass: "list__value pl-0 font-weight-bold monospace_font important_date",
            attrs: {
              "tooltip": "This is the Delivery Order's estimated close date (ECD)."
            }
          }, [_vm._v(" " + _vm._s(deliveryOrderDocument.estimatedCloseDate) + " ")]) : _vm._e(), !deliveryOrderDocument.minProjectedCloseDate && !deliveryOrderDocument.projectedCloseDate ? _c('p', {
            staticClass: "list__value pl-0 important_date"
          }, [_c('i', [_vm._v("no ECD")])]) : _vm._e()], 1), !deliveryOrderDocument.estimatedCloseDate ? _c('div', {
            staticClass: "delivery_order_alert"
          }, [_c('c-icon-fa', {
            staticClass: "mr-2",
            attrs: {
              "icon": _vm.$icons.warning,
              "icon-color": "white",
              "icon-size": "12px"
            }
          }), _vm._v(" No ECD found. ")], 1) : _vm._e(), deliveryOrderDocument.estimatedCloseDate && astPart.partReference.computedNeedDate && _vm.isAfter(_vm.dateFrom(deliveryOrderDocument.estimatedCloseDate), _vm.dateFrom(astPart.partReference.computedNeedDate)) ? _c('div', {
            staticClass: "delivery_order_alert"
          }, [_c('c-icon-fa', {
            staticClass: "mr-2",
            attrs: {
              "icon": _vm.$icons.warning,
              "icon-color": "white",
              "icon-size": "12px"
            }
          }), _c('p', {
            staticClass: "list__value"
          }, [_vm._v("Estimated close date occurs after need.")])], 1) : _vm._e()]);
        }), 0);
      }) : _vm._e()], 2)], 1);
    })], 2);
  }), 0)])]) : _vm._e()]), _vm.showModalMegaPartEdit && _vm.selectedAssemblyTemplateToEdit ? _c('ModalMegaPartEdit', {
    attrs: {
      "assembly-template-id": _vm.selectedAssemblyTemplateToEdit.id
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "part-updated": _vm.reset
    }
  }) : _vm._e(), _vm.showModalAutoHardwareListInfo && _vm.selectedMegaLeaf && _vm.selectedMegaLeaf.autoHardwareListId ? _c('ModalAutoHardwareListInfo', {
    attrs: {
      "id": _vm.selectedMegaLeaf.autoHardwareListId
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.resetJustModals.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalEventAllocationWindow ? _c('ModalEventAllocationWindowCreateEdit', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showModalCloneMega && _vm.selectedMegaLeaf ? _c('ModalMegaClone', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.resetJustModals
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalAssemblyTemplateInfo ? _c('ModalAssemblyTemplateInfo', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalUpdateNeed ? _c('ModalMegaNeedUpdate', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalUpdateMegaAllocationType ? _c('ModalMegaAllocationTypeUpdate', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };