var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app_timeline",
    class: {
      no_events: !_vm.plottedMarkers.length
    }
  }, [_vm.plottedMarkers.length ? _c('div', {
    staticClass: "timeline"
  }, [_vm._l(_vm.plottedFills, function (fill) {
    return _c('div', {
      key: fill.id,
      staticClass: "timeline_fill",
      class: {
        active: fill.isActive
      },
      style: {
        left: `${fill.translateX}%`,
        width: `${fill.width}%`,
        backgroundColor: fill.color
      }
    });
  }), _vm._l(_vm.plottedMarkers, function (marker, index) {
    return _c('div', {
      key: marker.id,
      class: {
        timeline_event: true,
        emphasize: marker.event.isEmphasized
      },
      style: {
        '--timeline-event-color': marker.event.iconColor
      }
    }, [_c('div', {
      staticClass: "marker marker_start",
      class: {
        duplicate_marker: _vm.isDuplicateDateMarker(marker),
        is_window: marker.isWindow,
        first_marker: index == 0,
        last_marker: index == _vm.plottedMarkers.length - 1
      },
      style: {
        color: marker.color,
        left: `${marker.translateX}%`,
        bottom: _vm.getVerticalOffsetForMarker(marker)
      }
    }, [_c('div', {
      staticClass: "event_label monospace_font"
    }, [_vm._v(_vm._s(marker.date))]), _c('div', {
      staticClass: "event_icon",
      on: {
        "mouseenter": function ($event) {
          return _vm.onEventIconMouseEnter(marker);
        },
        "mouseleave": function ($event) {
          return _vm.onEventIconMouseLeave();
        }
      }
    }, [_vm._t("event_icon", function () {
      return [_c('ButtonMiniAction', {
        attrs: {
          "icon": marker.event.icon,
          "icon-color": marker.event.iconColor,
          "icon-size": marker.event.isEmphasized ? marker.event.emphasizedIconSize : marker.event.iconSize,
          "tooltip": marker.tooltip || false,
          "tooltip-position": marker.translateX > 50 ? 'right' : 'left'
        },
        on: {
          "click": function ($event) {
            return _vm.emitEventClick(marker);
          }
        }
      })];
    }, {
      "marker": marker
    })], 2)])]);
  }), _c('div', {
    staticClass: "timeline_start"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.earliestDate) + " ")]), _c('small', [_vm._v("Earliest start")])]), _c('div', {
    staticClass: "timeline_end"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.latestDate) + " ")]), _c('small', [_vm._v("Latest end")])])], 2) : _c('div', [_c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" No plottable Events found. When Events are associated with this MEGA, a timeline will be displayed. ")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };