var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": "",
      "modal-width": "85vw"
    },
    on: {
      "modal-close": _vm.closeDialog,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "mb-2"
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Is this a Tool?"
          },
          model: {
            value: _vm.formDataPartReference.isTool,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "isTool", $$v);
            },
            expression: "formDataPartReference.isTool"
          }
        })], 1), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Description"
          },
          model: {
            value: _vm.formDataPartReference.drawingDescription,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "drawingDescription", $$v);
            },
            expression: "formDataPartReference.drawingDescription"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Drawing number"
          },
          model: {
            value: _vm.formDataPartReference.drawingNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "drawingNumber", $$v);
            },
            expression: "formDataPartReference.drawingNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "AsBuilt"
          },
          model: {
            value: _vm.formDataPartReference.asBuiltNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "asBuiltNumber", $$v);
            },
            expression: "formDataPartReference.asBuiltNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppSelectEnum', {
          attrs: {
            "display": _vm.SideDisplay,
            "enum": _vm.Side,
            "color": _vm.EntityType.ITEM_MASTER,
            "label": "Side",
            "sort": ""
          },
          model: {
            value: _vm.formDataPartReference.side,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "side", $$v);
            },
            expression: "formDataPartReference.side"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Size"
          },
          model: {
            value: _vm.formDataPartReference.size,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "size", $$v);
            },
            expression: "formDataPartReference.size"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Serial"
          },
          model: {
            value: _vm.formDataPartReference.serialNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "serialNumber", $$v);
            },
            expression: "formDataPartReference.serialNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Lot"
          },
          model: {
            value: _vm.formDataPartReference.lotNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "lotNumber", $$v);
            },
            expression: "formDataPartReference.lotNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppSelectEnum', {
          attrs: {
            "display": _vm.InventoryClassDisplay,
            "enum": _vm.InventoryClass,
            "sort": false,
            "value": _vm.formDataPartReference.itemClass && _vm.formDataPartReference.itemClass[0],
            "label": "Class",
            "monospace-selections": ""
          },
          on: {
            "input": function ($event) {
              _vm.formDataPartReference.itemClass = [$event];
            }
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "Calibration number"
          },
          model: {
            value: _vm.formDataPartReference.calibrationNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "calibrationNumber", $$v);
            },
            expression: "formDataPartReference.calibrationNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "CEI number"
          },
          model: {
            value: _vm.formDataPartReference.contractEndingItemNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "contractEndingItemNumber", $$v);
            },
            expression: "formDataPartReference.contractEndingItemNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid my-2"
        }, [_c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Birth"
          },
          model: {
            value: _vm.formDataPartReference.birthDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "birthDate", $$v);
            },
            expression: "formDataPartReference.birthDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Last calibration"
          },
          model: {
            value: _vm.formDataPartReference.lastCalibrationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "lastCalibrationDate", $$v);
            },
            expression: "formDataPartReference.lastCalibrationDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Usage life expiry"
          },
          model: {
            value: _vm.formDataPartReference.usageLifeExpirationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "usageLifeExpirationDate", $$v);
            },
            expression: "formDataPartReference.usageLifeExpirationDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Shelf life expiry"
          },
          model: {
            value: _vm.formDataPartReference.shelfLifeExpirationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "shelfLifeExpirationDate", $$v);
            },
            expression: "formDataPartReference.shelfLifeExpirationDate"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half"
        }, [_c('AppText', {
          attrs: {
            "label": "Location"
          },
          model: {
            value: _vm.formDataPartReference.location,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "location", $$v);
            },
            expression: "formDataPartReference.location"
          }
        })], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppTextarea', {
          attrs: {
            "label": "Notes"
          },
          model: {
            value: _vm.formDataPartReference.notes,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "notes", $$v);
            },
            expression: "formDataPartReference.notes"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSavePartReference
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(" Cancel"), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };