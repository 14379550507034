var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    staticClass: "cosmic_step_action_container px-4 py-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "action_container_1",
    attrs: {
      "xs5": ""
    }
  }, [_vm._t("actions")], 2), _c('v-flex', {
    staticClass: "action_container_2",
    attrs: {
      "xs2": ""
    }
  }, [!_vm.hideStepper && _vm.stepsStepNumberList.length ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldDisablePreviousButton,
      "icon-color": "var(--v-gray-base)",
      "icon-size": "28px",
      "icon": "fad fa-caret-circle-left",
      "tooltip": "Previous step"
    },
    on: {
      "click": _vm.onClickOfPreviousStepButton
    }
  }) : _vm._e(), !_vm.hideStepper && _vm.stepsStepNumberList.length ? _c('div', {
    staticClass: "step_number"
  }, [_c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.currentViewingStep && _vm.currentViewingStep.stepNumber))])];
      }
    }], null, false, 1323754784)
  }, [_c('span', [_vm._v("Currently viewing step " + _vm._s(_vm.currentViewingStep && _vm.currentViewingStep.stepNumber) + ".")])])], 1)]) : _vm._e(), !_vm.hideStepper && _vm.stepsStepNumberList.length ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldDisableNextButton,
      "icon-color": "var(--v-gray-base)",
      "icon-size": "28px",
      "icon": "fad fa-caret-circle-right",
      "tooltip": "Next step"
    },
    on: {
      "click": _vm.onClickOfNextStepButton
    }
  }) : _vm._e()], 1), _c('v-flex', {
    staticClass: "action_container_3",
    attrs: {
      "xs5": ""
    }
  }, [_vm.stepsStepNumberList.length ? _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({
          staticClass: "jump_to_step"
        }, on), [_c('AppSelect', {
          staticClass: "just_to_step_select",
          attrs: {
            "chips": false,
            "select-options": _vm.stepsStepNumberList,
            "width": "300px",
            "clearable": "",
            "label": "Jump to step",
            "value": _vm.value
          },
          on: {
            "input": _vm.onStepSelect
          }
        })], 1)];
      }
    }], null, false, 675902721)
  }, [_c('span', [_vm._v("Jump to step")])])], 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };