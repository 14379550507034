var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mb-2"
  }, [_vm.documentId ? _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": ""
    }
  }, [_vm._v("Autosave enabled for Tags. ")]) : _vm._e(), _c('AppSelect', {
    attrs: {
      "color": _vm.computedColor,
      "label": _vm.label,
      "select-options": _vm.selectOptions,
      "allow-create": "",
      "multiple": ""
    },
    on: {
      "option-create": _vm.addTag
    },
    model: {
      value: _vm.tags,
      callback: function ($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };