/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalMegaNeedUpdate.vue?vue&type=template&id=6449bf75"
import script from "./ModalMegaNeedUpdate.ts?vue&type=script&lang=ts&external"
export * from "./ModalMegaNeedUpdate.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports