var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { AlertType, EntityType, IAppChip } from '~/nasa_ui/types';
import { ErrorUtility } from '~/nasa_ui/utils/ErrorUtility';
let TagsInput = class TagsInput extends Vue {
    document = null;
    EntityType = EntityType;
    errorMessages = [];
    existingTags = [];
    inputValue = '';
    isFetchingExistingTags = false;
    tags = [];
    color;
    // Passing documentId enables autosaving of tags on change
    documentId;
    label;
    value;
    disabled;
    get computedChips() {
        return (this.value || []).map((tag) => ({
            text: tag,
            value: tag,
            close: !this.disabled,
            color: this.computedColor,
            textColor: 'white'
        }));
    }
    get computedColor() {
        return this.color || this.documentId ? EntityType.DOCUMENT : 'var(--v-primary-base)';
    }
    get computedExistingTags() {
        // filter out ones already selected
        return this.existingTags.filter((existingTag) => !(this.value || []).some((t) => t === existingTag));
    }
    get selectOptions() {
        return this.value.map((tag) => ({
            displayText: tag,
            value: tag
        }));
    }
    get userHasEnteredDuplicateTag() {
        return this.computedChips.some((chip) => chip.text === this.inputValue);
    }
    addTag(tag) {
        this.inputValue = tag;
        this.onEnter();
    }
    onEnter() {
        if (this.userHasEnteredDuplicateTag) {
            return;
        }
        const newTag = this.inputValue;
        const tags = !this.value ? [newTag] : [...this.value, newTag];
        this.inputValue = '';
        if (this.documentId) {
            this.saveTags(tags);
        }
        return this.$emit('input', tags);
    }
    removeTag(tag) {
        const tags = (this.value || []).filter((t) => t !== tag.value);
        if (this.documentId) {
            this.saveTags(tags);
        }
        return tags;
    }
    async onInputValueChange() {
        if (!this.inputValue) {
            this.existingTags = [];
            return;
        }
        this.errorMessages = this.userHasEnteredDuplicateTag ? [`${this.inputValue} has already been added`] : [];
        const resp = await this.fetchExistingTagsQuery(this.inputValue);
        this.existingTags = resp.data.autocompleteDocumentTags;
    }
    fetchExistingTagsQuery(tagStartsWith) {
        return this.$apollo.query({
            query: gql `
        query AutocompleteDocumentTags($tagStartsWith: String!) {
          autocompleteDocumentTags(tagStartsWith: $tagStartsWith)
        }
      `,
            variables: {
                tagStartsWith
            }
        });
    }
    saveTags(tags) {
        if (this.documentId) {
            return this.saveDocumentTags(tags);
        }
    }
    async saveDocumentTags(tags) {
        try {
            const documentTagsPatchInput = {
                id: this.documentId,
                tags
            };
            await this.$apollo.mutate({
                mutation: gql `
          mutation UpdateDocumentTags($input: ForceUpdateDocumentInput!) {
            forceUpdateDocument(input: $input) {
              clientMutationId
            }
          }
        `,
                variables: {
                    input: documentTagsPatchInput
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Tags updated.'
            });
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async fetchDocument() {
        if (!this.documentId) {
            return;
        }
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query DocumentForTagsInput($id: UUID!) {
            documentById(id: $id) {
              id
              nodeId
              computedStatus
            }
          }
        `,
                variables: {
                    id: this.documentId
                }
            });
            this.document = resp.data.documentById;
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    onChangeOfValue(value) {
        if (!value || !value.length) {
            this.tags = [];
            return;
        }
        if (JSON.stringify(value) !== JSON.stringify(this.tags)) {
            this.tags = [...value];
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], TagsInput.prototype, "color", void 0);
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", Object)
], TagsInput.prototype, "documentId", void 0);
__decorate([
    Prop({
        default: 'Tags',
        type: String
    }),
    __metadata("design:type", String)
], TagsInput.prototype, "label", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], TagsInput.prototype, "value", void 0);
__decorate([
    Prop({
        required: false,
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], TagsInput.prototype, "disabled", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof IAppChip !== "undefined" && IAppChip) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TagsInput.prototype, "removeTag", null);
__decorate([
    Watch('inputValue'),
    Debounce(300),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TagsInput.prototype, "onInputValueChange", null);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TagsInput.prototype, "fetchDocument", null);
__decorate([
    Watch('value', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], TagsInput.prototype, "onChangeOfValue", null);
TagsInput = __decorate([
    Component
], TagsInput);
export default TagsInput;
