var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    }
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "file"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "disabled": _vm.disabled,
      "icon": "fad fa-file-search"
    },
    on: {
      "click": _vm.onClickOfFileSelect
    }
  }, [_vm._v(" Choose a file to upload ")])], 1), _c('input', _vm._b({
    key: _vm.uniqueKey,
    ref: "fileInput",
    staticClass: "file",
    attrs: {
      "disabled": _vm.disabled,
      "id": "file",
      "name": "file",
      "type": "file"
    },
    on: {
      "change": _vm.onFileChange,
      "input": _vm.onFileChange
    }
  }, 'input', {
    ..._vm.$attrs
  }, false)), _c('div', {
    staticClass: "filename",
    class: {
      'font-italic': !_vm.value
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])]), _c('div', {
    staticClass: "col-full"
  }, [_vm.showMaxFileSizeExceededMessage ? _c('div', {
    staticClass: "red--text text--darken-2"
  }, [_vm._v(" " + _vm._s(_vm.maxFileSizeExceededMessage) + " ")]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };